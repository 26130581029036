import React, { useState, useEffect } from 'react'
import Typed from 'react-typed'
import { css } from '@emotion/core'

// Components
import { ChevronsDown } from 'react-feather'
import { Box, Flex, Text, Heading, SimpleGrid } from '@chakra-ui/core'

// Assets
const napa_edit = 'https://www.datocms-assets.com/16102/1646773735-napa_promo.mp4'

const Banner = ({ slides }) => {
    const [typedRef, setTypedRef] = useState()

    const strings = slides.reduce((list: string[], { title }: { title: string }) => {
        return [...list, title]
    }, [])

    useEffect(() => {
        setTimeout(() => {
            typedRef && typedRef.start()
        }, 1500)
    })
    return (
        <Box position="relative">
            <SimpleGrid columns={[1, 1, 2]} spacing={0}>
                <Flex
                    backgroundColor="black"
                    flexDirection="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    textAlign="left"
                    padding={['3rem 1rem', '3rem 1rem', '3rem']}
                    height={['initial', 'initial', '100vh']}
                >
                    <Box maxWidth="500px" width="100%">
                        <Heading
                            textAlign="left"
                            fontFamily="heading"
                            color="white"
                            fontSize={['heading3', 'heading3', 'display1']}
                            minHeight="150px"
                            as="h2"
                        >
                            We help{' '}
                            <Typed
                                strings={strings.slice(0, 2)}
                                showCursor={false}
                                backDelay={5000}
                                typeSpeed={20}
                                backSpeed={5}
                                loop
                            />
                        </Heading>
                    </Box>
                    <Box maxWidth="500px" width="100%">
                        <Heading
                            minHeight="150px"
                            display="block"
                            textAlign="left"
                            fontFamily="heading"
                            color="white"
                            fontSize={['heading3', 'heading3', 'display1']}
                            as="h2"
                        >
                            We help{' '}
                            <Typed
                                strings={strings.slice(2, 4)}
                                loop
                                stopped
                                typedRef={(typed: any) => setTypedRef(typed)}
                                backDelay={5000}
                                showCursor={false}
                                typeSpeed={20}
                                backSpeed={5}
                            />
                        </Heading>
                    </Box>

                    <Box maxWidth="500px">
                        <Heading
                            fontFamily="heading"
                            color="white"
                            fontSize={['heading3', 'heading3', 'display1']}
                            as="h2"
                        >
                            We are the space company.
                        </Heading>
                    </Box>
                </Flex>
                <Box height={['450px', '600px', '100vh']}>
                    <video
                        css={css`
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        `}
                        loop
                        muted
                        playsInline
                        autoPlay
                        preload="auto"
                    >
                        <source src={napa_edit} />
                    </video>
                </Box>
            </SimpleGrid>
            <Flex
                position="absolute"
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                bottom="2rem"
                left="0"
                right="0"
            >
                <Text
                    fontFamily="body"
                    color="white"
                    fontWeight="400"
                    textTransform="uppercase"
                    margin="0 auto"
                    fontSize="small"
                    mb="0"
                >
                    View our work
                </Text>

                <ChevronsDown color="white" size="24" />
            </Flex>
        </Box>
    )
}

export default Banner
