import React, { useState } from 'react'
import { css } from '@emotion/core'
import { Box, Flex, Text, Heading, PseudoBox, Image } from '@chakra-ui/core'

type LocationItemProps = {
    name: string
    location: string
    brand: string
    images: {
        url: string
        alt?: string
    }[]
}

const LocationItem = ({ name, location, brand, images }: LocationItemProps) => {
    const [tabIndex, setTabIndex] = useState(0)

    return (
        <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            position="relative"
            onMouseEnter={() => setTabIndex(1)}
            onMouseLeave={() => setTabIndex(0)}
        >
            <Image
                src={images[0].url}
                width="100%"
                height="100%"
                minHeight="450px"
                maxHeight="450px"
                css={css`
                    object-fit: cover;
                    transition: 200ms ease-in;
                `}
                alt={images[0].alt}
            />
            <Image
                src={images[1].url}
                position="absolute"
                top="0"
                right="0"
                left="0"
                width="100%"
                height="100%"
                opacity={tabIndex === 1 ? 1 : 0}
                zIndex="3"
                css={css`
                    object-fit: cover;
                    transition: 200ms ease-in;
                `}
                alt={images[1].alt}
            />
            <Box
                opacity="0.24"
                bg="black"
                width="100%"
                height="100%"
                position="absolute"
                top="0"
                left="0"
                zIndex="9"
            ></Box>
            <Box
                zIndex="9"
                position="absolute"
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                left="0"
                top="0"
                right="0"
                width="100%"
                height="100%"
            >
                <Heading
                    as="h4"
                    fontSize={['heading2', 'heading2', 'heading3']}
                    fontFamily="heading"
                    color="white"
                >
                    {name}
                </Heading>
                <Text
                    fontFamily="body"
                    textTransform="uppercase"
                    fontSize={['body', 'body', 'subtitle']}
                    color="white"
                >
                    {location}
                </Text>
                <Flex position="absolute" left="1rem" bottom=".5rem" alignItems="center">
                    <Text color="white" fontSize="small" fontFamily="body" mr="0.5rem">
                        {brand}
                    </Text>
                    <Flex>
                        {images.length > 1 &&
                            images.map((_, index: number) => (
                                <PseudoBox
                                    key={index}
                                    height="12px"
                                    width="12px"
                                    mr="4px"
                                    borderRadius="rounded"
                                    bg="white"
                                    opacity={index === tabIndex ? 1 : 0.5}
                                    _hover={{ opacity: 1 }}
                                />
                            ))}
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    )
}

export default LocationItem
