import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Box, Image, Flex, Text, Heading, SimpleGrid } from '@chakra-ui/core'
import { Link } from 'components'

// Sections
import Layout from '../layouts/Layout'

// Modules
import LocationItem from 'modules/LocationItem'
import HomeBanner from 'modules/Banner'

// Assets
import Logo from 'assets/images/tsc_logo.png'
import Logo_white from 'assets/images/tsc_logo_white.png'

const IndexPage = ({ data: { datoCmsHomev2 } }: any) => {
    const { banner, logos, locationsSubtitle, locationsTitle, locations } = datoCmsHomev2
    const [slides] = useState(banner)

    return (
        <Layout>
            <Box
                bg={['black', 'black', 'initial']}
                position={['initial', 'initial', 'fixed']}
                top="1rem"
                right="1rem"
                zIndex="999"
                padding={['3rem 1rem 0', '3rem 1rem 0', 0]}
            >
                <Image display={['none', 'none', 'initial']} src={Logo} width="100px"></Image>
                <Image
                    display={['initial', 'initial', 'none']}
                    src={Logo_white}
                    width="100px"
                ></Image>
            </Box>
            <HomeBanner slides={slides} />
            <Box pt="8rem" px="1rem">
                <SimpleGrid
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="hd"
                    margin="0 auto"
                    columns={[2, 2, 6]}
                    spacing="2rem"
                >
                    {logos.map(({ url, alt }: any, i: number) => (
                        <Image
                            key={i}
                            margin="0 auto"
                            src={url}
                            maxHeight="35px"
                            alt={alt}
                            px="1rem"
                        />
                    ))}
                </SimpleGrid>
            </Box>
            <Box pt="8rem">
                <Flex
                    display="flex"
                    maxWidth="575px"
                    m="0 auto"
                    flexDirection="column"
                    alignItems="center"
                >
                    <Heading
                        textAlign="center"
                        margin="0 auto"
                        as="h3"
                        color="black"
                        fontSize={['heading3', 'heading3', 'display1']}
                        fontFamily="heading"
                        mb="1rem"
                    >
                        {locationsTitle}
                    </Heading>
                    <Text
                        textAlign="center"
                        color="black80"
                        fontSize="subtitle"
                        fontFamily="heading"
                        mb="2rem"
                    >
                        {locationsSubtitle}
                    </Text>
                    <Text
                        fontFamily="body"
                        fontSize={['subtitle', 'heading2', 'heading2']}
                        color="#ED8A0A"
                        fontWeight="bold"
                    >
                        {' '}
                        <Link color="#ED8A0A" to="mailto:takeoff@thespaceco.net">
                            Get in touch
                        </Link>
                    </Text>
                </Flex>
            </Box>
            <Box
                pt={['3rem', '3rem', '6rem']}
                pb={['3rem', '3rem', '6rem']}
                maxWidth="hd"
                m="0 auto"
                px="1rem"
            >
                <SimpleGrid columns={[1, 1, 2]} spacing={['1rem', '1rem', '2rem']}>
                    {locations.map(({ name, location, brand, images }: any) => (
                        <LocationItem key={name} {...{ name, location, brand, images }} />
                    ))}
                </SimpleGrid>
            </Box>
        </Layout>
    )
}

export const query = graphql`
    {
        datoCmsHomev2 {
            banner {
                title
                image {
                    url
                    alt
                }
            }
            logos {
                url
            }
            locationsTitle
            locationsSubtitle
            locations {
                name
                location
                brand
                images {
                    url
                }
            }
        }
    }
`

export default IndexPage
